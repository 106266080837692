import { styled } from "@patientmpower/spiro";

export const RoutesPatientPageMobile = styled("div", {
  position: "relative",
  display: "flex",
  justifyContent: "flex-start",

  width: "100%",
  marginBottom: "10px",

  color: "$gray-80",
  fontSize: "12px",
  fontWeight: "400",

  p: {
    margin: "0 2px 0 2px",
  },
});

export const AddMeasurementButton = styled("div", {
  position: "absolute",
  top: -24,
  right: 10,

  color: "$charcoal",
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "$openSans",
  lineHeight: "16px",

  cursor: "pointer",

  "&:hover": {
    color: "$primary",
  },
});

export const PinnedSectionTitle = styled("div", {
  fontFamily: "$nunito",

  fontWeight: "700",
  lineHeight: "34px",
  fontSize: "$text-xxl",

  color: "$gray-100",

  "@sm": {
    lineHeight: "28px",
  },
});

export const TitleContainer = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const MeasurementContainer = styled("div", {
  height: "100%",
  width: "100%",

  padding: "24px 0px 0 0",
});

export const HeaderContainer = styled("div", {
  display: "flex",
  flexDirection: "column",

  marginLeft: "40px",
  marginBottom: "28px",

  "&:last-child": {
    marginBottom: "28px",
  },
});

export const PinnedTile = styled("div", {
  width: "100%",
  height: "680px",

  display: "flex",
  position: "relative",

  padding: "12px 32px 16px 32px",

  backgroundColor: "$white",
  borderRadius: "12px",
  border: "1px solid $gray-40",
});

export const OptionsContainer = styled("div", {
  position: "absolute",
  top: 14,
  right: 28,

  display: "flex",
  justifyContent: "center",
  alignItems: "center",

  cursor: "pointer",
});

export const PinnedToogleContainer = styled("div", {
  top: 20,
  right: -10,
  width: 218,
  height: 48,

  zIndex: 3000,
  borderRadius: "16px",
  position: "absolute",
  background: "white",
  boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.12)",
  border: "1px solid var(--colors-gray-50)",
  padding: "14px 22px",

  color: "$charcoal",
  fontSize: "10px",
  fontWeight: 400,
  fontFamily: "$openSans",
  lineHeight: "16px",

  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: 22,
});

export const DotsContainer = styled("div", {});

export const NewDataContainer = styled("div", {
  marginLeft: "5px",
  color: "$gray-80",

  fontFamily: "$nunito",
  fontWeight: "600px",
  fontSize: "16px",
  lineHeight: "34px",
});
