import { createColumn, Table } from "@patientmpower/spiro";
import { ColumnDef } from "@tanstack/react-table";
import dayjs from "dayjs";
import { useMemo, useState } from "react";

import {
  IPromsTableData,
  ISurveyFormQuestionTableData,
  ISurveyPromsTableData,
} from "../../../../../../../@types/Proms";
import { ArrowLeft } from "../../../../../../../assets/icons/ArrowLeft";
import { getPatientSurveyFormsData } from "../../../../../../../hooks/queries/proms";
import { ModalSkeleton } from "../../../../MeasurementModal/components/ModalSkeleton";
import {
  BackArrowContainer,
  PromDetailsModalContainer,
  SubHeading,
  TableContainer,
  Title,
} from "./PromDetailsModal.style";
import {
  promDetailsTableColumns,
  promQuestionsAndAnswersTableColumns,
} from "./promDetailsTableColumns";

type PromDetailsModalProps = {
  patientId: number;
  formData: IPromsTableData;
  onClose: () => void;
};

export function PromDetailsModal({
  patientId,
  formData,
  onClose,
}: PromDetailsModalProps) {
  const [showQuestionsAndAnswers, setShowQuestionsAndAnswers] = useState(false);
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState<
    ISurveyFormQuestionTableData[]
  >([]);
  const [completedDate, setCompletedDate] = useState("");

  const { surveyForms, isFetching } = getPatientSurveyFormsData(
    patientId,
    formData.id
  );

  let newTableColums: ColumnDef<any, string>[] = [];

  function roundScoreToOneDecimal(score: number) {
    return Math.round(score * 10) / 10;
  }

  const parsedSurveyForms = useMemo(() => {
    if (!surveyForms) return [];

    const domains = new Set<string>();

    surveyForms.forEach((surveyForm) => {
      if (surveyForm.scores) {
        surveyForm.scores.forEach((score) => {
          if (score.type === "Domain" && score.domain) {
            domains.add(score.domain);
          }
        });
      }
    });

    newTableColums = [...promDetailsTableColumns];

    const newColumns: ColumnDef<any, string>[] = [];

    domains.forEach((domain) =>
      newColumns.push(
        createColumn({
          keyName: domain,
          header: () => domain,
          cell: (info) => info.renderValue(),
        })
      )
    );

    newTableColums.splice(1, 0, ...newColumns);

    const surveyFormList = surveyForms.map(
      (surveyForm: ISurveyPromsTableData) => {
        const domainScores: { [key: string]: string | number } = {};

        domains.forEach((domain) => {
          const domainScore = surveyForm.scores?.find(
            (score) => score.domain === domain
          )?.score;
          domainScores[domain] =
            domainScore !== undefined && domainScore >= 0
              ? roundScoreToOneDecimal(domainScore)
              : "-";
        });

        const totalScore = surveyForm.scores?.find(
          (x) => x.type === "Total"
        )?.score;

        return {
          id: surveyForm.id,
          time: surveyForm.time,
          questions: surveyForm.questions.filter(
            (question) => question.type !== "Informative"
          ),
          surveyResult: surveyForm.surveyResult ?? "-",
          total: totalScore ? roundScoreToOneDecimal(totalScore) : "-",
          ...domainScores,
        };
      }
    );

    return surveyFormList;
  }, [surveyForms]);

  const handleBackwardsArrowClick = () => {
    if (showQuestionsAndAnswers) {
      setShowQuestionsAndAnswers(false);
    } else {
      onClose();
    }
  };

  const handleRowClick = (rowData: ISurveyPromsTableData) => {
    const dataToDisplay: ISurveyFormQuestionTableData[] = [];

    rowData.questions.forEach((data) => {
      let { answerText } = data;
      if (answerText)
        if (data.type === "Date") {
          answerText = dayjs(answerText).format("MMM D, YYYY, hh:mma");
        }

      dataToDisplay.push({
        key: data.key,
        question: data.questionText,
        answer: answerText,
        type: data.type,
      });
    });

    setCompletedDate(rowData.time);
    setQuestionsAndAnswers(dataToDisplay);

    setTimeout(() => {
      setShowQuestionsAndAnswers(true);
    }, 1);
  };

  if (isFetching) {
    return (
      <PromDetailsModalContainer>
        <Title>{formData.name}</Title>

        <TableContainer>
          <ModalSkeleton />
        </TableContainer>
      </PromDetailsModalContainer>
    );
  }

  return (
    <PromDetailsModalContainer>
      {showQuestionsAndAnswers ? (
        <BackArrowContainer onClick={handleBackwardsArrowClick}>
          <ArrowLeft />
        </BackArrowContainer>
      ) : null}

      <Title>{formData.name}</Title>

      {!showQuestionsAndAnswers ? (
        <TableContainer>
          <Table
            data={parsedSurveyForms}
            columns={newTableColums}
            hasBorder={false}
            onRowClick={handleRowClick}
            rowPadding
            fontSize={14}
          />
        </TableContainer>
      ) : (
        <>
          <SubHeading>Date completed: {completedDate}</SubHeading>
          <TableContainer>
            <Table
              data={questionsAndAnswers}
              columns={promQuestionsAndAnswersTableColumns}
              hasBorder={false}
              rowPadding
              fontSize={14}
            />
          </TableContainer>
        </>
      )}
    </PromDetailsModalContainer>
  );
}
