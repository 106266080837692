import { Button, Checkbox } from "@patientmpower/spiro";
import { useState } from "react";

import { Notification } from "../../../../@types/Notification";
import { IArchivePatientRequest, IPatient } from "../../../../@types/Patient";
import { patientService } from "../../../../services/patientService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import {
  CancelButton,
  CheckboxContainer,
  ModalContainer,
  Row,
  SectionContainer,
  SubmitContainer,
  Title,
} from "./ArchivePatientModal.styles";

type ArchivePatientModalProps = {
  content: IPatient;
  isReactivate: boolean;
  onClose(notification?: Notification): void;
};

export function ArchivePatientModal({
  content,
  isReactivate,
  onClose,
}: ArchivePatientModalProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [unlinkDevices, setUnlinkDevices] = useState(false);

  mixpanelActions.track(
    `VisitedScreen: ${isReactivate ? "Reactivate" : "Archive"}PatientModal`
  );

  const handleArchivePatient = async (sendEmail: boolean) => {
    setIsLoading(true);

    const request: IArchivePatientRequest = {
      patientId: content.id,
      sendEmail,
      unlinkDevices,
    };

    const result = await patientService.archivePatient(request);

    const notification: Notification = {
      show: true,
      message: "",
      type: "success",
      width: "max-content",
    };

    setIsLoading(false);

    if (result.status >= 200 && result.status < 300) {
      notification.message = `Patient successfully ${
        isReactivate ? "reactivated" : "archived"
      }`;
      onClose(notification);
    } else {
      notification.message = `Error ${
        isReactivate ? "reactivating" : "archiving"
      } patient, please try again`;
      notification.type = "error";
      onClose(notification);
    }
  };

  return (
    <ModalContainer>
      <Title>{`${isReactivate ? "Reactivate" : "Archive"} patient`}</Title>

      <SectionContainer>
        <Row>
          <p>Name</p>
          <p>{content.name}</p>
        </Row>
        <Row>
          <p>Hospital ID</p>
          <p>{content.hospitalId}</p>
        </Row>
        <Row>
          <p>Sign up date</p>
          <p>{content.signUpDate}</p>
        </Row>
      </SectionContainer>

      {!isReactivate ? (
        <CheckboxContainer>
          <Checkbox
            label=""
            onChange={(value) => setUnlinkDevices(value)}
            checked={unlinkDevices}
          />
          <p>
            <b style={{ fontWeight: "600" }}>Unlink devices</b> such as weighing
            scales
          </p>
        </CheckboxContainer>
      ) : null}

      <SubmitContainer>
        <Button
          label={`${isReactivate ? "Reactivate" : "Archive"} and send email`}
          type="submit"
          onClick={() => {
            handleArchivePatient(true);
            mixpanelActions.track(
              `User Action: ${
                isReactivate
                  ? "ReactivateAndSendEmailButton"
                  : "ArchiveAndSendEmailButton"
              }`
            );
          }}
          isLoading={isLoading}
        />

        <CancelButton
          onClick={() => {
            if (isReactivate) {
              onClose();
            } else {
              handleArchivePatient(false);
            }

            mixpanelActions.track(
              `User Action: ${
                isReactivate ? "ReactivateCancelButton" : "ArchiveButton"
              }`
            );
          }}
          style={{ marginLeft: "-30px" }}
        >
          {isReactivate ? "Cancel" : "Archive"}
        </CancelButton>
      </SubmitContainer>
    </ModalContainer>
  );
}
