import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useEffect, useRef, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import { MeasurementTypes } from "../../@types/Measurements";
import {
  colapsedSectionsPropertyNames,
  ICollapsedSectionList,
} from "../../@types/Patient";
import { IPortalPreferences } from "../../@types/Preferences";
import { queryClient } from "../../config/query";
import { VIEWED_FIRST_PATIENT } from "../../constants/localStorageKeys";
import { useMeasurementConfigs } from "../../hooks/queries/measurement-configs";
import { usePatientInfo } from "../../hooks/queries/patients";
import { useChildRoutes } from "../../hooks/useChildRoutes";
import { useUserPreferences } from "../../hooks/useUserPreferences";
import { PageLayout } from "../../layout/components/PageLayout";
import { userService } from "../../services/userService";
import { mixpanelActions } from "../../utils/mixpanel";
import { randomIntFromInterval } from "../../utils/random";
import { scrollToSectionPatientPage } from "../../utils/scrollToSectionPatientPage";
import { AlertsSection } from "./components/AlertsSection";
import { DevicesSection } from "./components/DevicesSection";
import { JournalsSection } from "./components/JournalsSection";
import { LabsSection } from "./components/LabsSection";
import { MeasurementSection } from "./components/MeasurementsSection";
import { MedicationsSection } from "./components/MedicationsSection";
import { MessagesSection } from "./components/MessagesSection";
import { NotesSection } from "./components/NotesSection";
import { PatientLateralMenuDesktop } from "./components/PatientLateralMenu";
import { PatientSubNav } from "./components/PatientSubNav";
import { PinnedSection } from "./components/PinnedSection";
import { PromsSection } from "./components/PromsSection";
import { SpirometrySection } from "./components/SpirometrySection";
import {
  LateralMenuContainer,
  LoadingOverlay,
  PatientPageContainer,
  PatientPageContent,
  StyledSection,
} from "./PatientPage.styles";
import "react-spring-bottom-sheet/dist/style.css";

dayjs.extend(utc);

export function PatientPage() {
  const location = useLocation();
  const { patientId } = useParams();

  const overviewSectionRef = useRef<null | HTMLDivElement>(null);
  const measurementsSectionRef = useRef<null | HTMLDivElement>(null);
  const spirometrySectionRef = useRef<null | HTMLDivElement>(null);
  const alertsSectionRef = useRef<null | HTMLDivElement>(null);
  const promsSectionRef = useRef<null | HTMLDivElement>(null);
  const labsSectionRef = useRef<null | HTMLDivElement>(null);
  const notesSectionRef = useRef<null | HTMLDivElement>(null);
  const medicationSectionRef = useRef<null | HTMLDivElement>(null);
  const journalSectionRef = useRef<null | HTMLDivElement>(null);
  const messagesSectionRef = useRef<null | HTMLDivElement>(null);
  const devicesSectionRef = useRef<null | HTMLDivElement>(null);
  const pinnedSectionRef = useRef<null | HTMLDivElement>(null);

  const { patient, isFetching, isRefetching } = usePatientInfo({ patientId });

  const { hideChildRoute, showChildRoute } = useChildRoutes();

  const { configs: spirometryConfigs, isFetching: isSpirometryConfigFetching } =
    useMeasurementConfigs({
      patientId: patient?.id,
      section: "spirometry",
    });

  const {
    configs: measurementsConfigs,
    isFetching: isMeasurementConfigFetching,
  } = useMeasurementConfigs({
    patientId: patient?.id,
    section: "measurements",
  });

  const { configs: labResultsConfigs, isFetching: isLabResultConfigFetching } =
    useMeasurementConfigs({
      patientId: patient?.id,
      section: "labResults",
    });

  const { portalPreferences, changePortalPreferences } = useUserPreferences();

  document.title = `Patient Page - patientMpower`;

  const [patientCollapsedSettingsIndex, setPatientCollapsedSettingsIndex] =
    useState<number>(-1);
  const [isPatientLateralMenuOpen, setIsPatientLateralMenuOpen] =
    useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [hasPinnedGraph, setHasPinnedGraph] = useState(false);
  const [pinnedGraphKey, setPinnedGraphKey] = useState("123456");

  const defaultCollapsedSection = {
    value: false,
    collapsedDate: "",
  };

  const [collapsedSections, setCollapsedSections] =
    useState<ICollapsedSectionList>({
      spirometrySection: defaultCollapsedSection,
      measurementSection: defaultCollapsedSection,
      labsSection: defaultCollapsedSection,
      alertsSection: defaultCollapsedSection,
      promsSection: defaultCollapsedSection,
      medicationsSection: defaultCollapsedSection,
      journalsSection: defaultCollapsedSection,
      notesSection: defaultCollapsedSection,
      messagesSection: defaultCollapsedSection,
      devicesSection: defaultCollapsedSection,
      pinnedSection: defaultCollapsedSection,
    });

  useEffect(() => {
    if (portalPreferences.collapsedSectionsConfigList) {
      const parsedPatientCollapsedSettingsList = JSON.parse(
        portalPreferences.collapsedSectionsConfigList
      );

      const patientCollapsedSettingsIndex =
        parsedPatientCollapsedSettingsList.findIndex(
          (element: {
            patientId: number;
            ICollapsedSections: ICollapsedSectionList;
          }) => element.patientId.toString() === patientId
        );

      if (patientCollapsedSettingsIndex > -1) {
        setPatientCollapsedSettingsIndex(patientCollapsedSettingsIndex);

        setCollapsedSections(
          parsedPatientCollapsedSettingsList[patientCollapsedSettingsIndex]
            .collapsedSectionsConfig
        );
      }
    }
  }, []);

  const handleOnChangeCollapsedSections = async (
    updatedCollapsedSectionsConfig: ICollapsedSectionList
  ) => {
    let updatedCollapsedSectionsConfigList = [];

    if (portalPreferences.collapsedSectionsConfigList) {
      updatedCollapsedSectionsConfigList = JSON.parse(
        portalPreferences.collapsedSectionsConfigList
      );
      if (patientCollapsedSettingsIndex !== -1) {
        updatedCollapsedSectionsConfigList[
          patientCollapsedSettingsIndex
        ].collapsedSectionsConfig = updatedCollapsedSectionsConfig;
      } else {
        updatedCollapsedSectionsConfigList.push({
          patientId: patient.id,
          collapsedSectionsConfig: updatedCollapsedSectionsConfig,
        });

        const updatedPatientCollapsedSettingsIndex =
          updatedCollapsedSectionsConfigList.length - 1;

        setPatientCollapsedSettingsIndex(updatedPatientCollapsedSettingsIndex);
      }
    } else {
      updatedCollapsedSectionsConfigList.push({
        patientId: patient.id,
        collapsedSectionsConfig: updatedCollapsedSectionsConfig,
      });
      setPatientCollapsedSettingsIndex(0);
    }

    const parsedUpdatedCollapsedSectionsConfigList = JSON.stringify(
      updatedCollapsedSectionsConfigList
    );

    const newPortalPreferences: IPortalPreferences = {
      ...portalPreferences,
      collapsedSectionsConfigList: parsedUpdatedCollapsedSectionsConfigList,
    };
    changePortalPreferences(newPortalPreferences);
    await userService.updateCollapsedSectionsConfig(
      parsedUpdatedCollapsedSectionsConfigList
    );
  };

  const updateColapsedSections = (section: colapsedSectionsPropertyNames) => {
    const formatedSectionString =
      section.charAt(0).toUpperCase() +
      section.slice(1).replace(/([a-z])([A-Z])/g, "$1 $2");

    setCollapsedSections((prevObject) => {
      const updatedCollapsedValue = !prevObject[section].value;
      mixpanelActions.track(
        `UserAction: ${
          updatedCollapsedValue ? "Collapse" : "Expand"
        } ${formatedSectionString}`
      );

      const updatedCollapsedSectionsConfig = {
        ...prevObject,
        [section]: {
          value: updatedCollapsedValue,
          collapsedDate: updatedCollapsedValue ? dayjs.utc().toISOString() : "",
        },
      };

      handleOnChangeCollapsedSections(updatedCollapsedSectionsConfig);
      return updatedCollapsedSectionsConfig;
    });
  };

  useEffect(() => {
    if (
      !isSpirometryConfigFetching &&
      (!spirometryConfigs || spirometryConfigs.length === 0)
    ) {
      hideChildRoute("Spirometry");
    }
  }, [isSpirometryConfigFetching, spirometryConfigs]);

  useEffect(() => {
    if (
      !isMeasurementConfigFetching &&
      (!measurementsConfigs || measurementsConfigs.length === 0)
    ) {
      hideChildRoute("Measurements");
    }
  }, [isMeasurementConfigFetching, measurementsConfigs]);

  useEffect(() => {
    if (
      !isLabResultConfigFetching &&
      (!labResultsConfigs || labResultsConfigs.length === 0)
    ) {
      hideChildRoute("Labs");
    }
  }, [isLabResultConfigFetching, labResultsConfigs]);

  useEffect(() => {
    mixpanelActions.track("Visit Screen: Patient Page");
    const viewedFirstPatient = localStorage.getItem(VIEWED_FIRST_PATIENT);
    if (viewedFirstPatient && viewedFirstPatient === "no") {
      setIsPatientLateralMenuOpen(true);
    }
    localStorage.setItem(VIEWED_FIRST_PATIENT, "yes");

    return () => {
      queryClient.invalidateQueries([
        `patient-info-${patientId}`,
        `patient-monitoring-info-${patientId}`,
      ]);
    };
  }, [patientId]);

  useEffect(() => {
    if (
      spirometryConfigs === null ||
      measurementsConfigs === null ||
      labResultsConfigs === null
    ) {
      return;
    }

    scrollToSectionPatientPage({
      overviewSectionRef,
      measurementsSectionRef,
      spirometrySectionRef,
      labsSectionRef,
      alertsSectionRef,
      promsSectionRef,
      notesSectionRef,
      medicationSectionRef,
      journalSectionRef,
      messagesSectionRef,
      devicesSectionRef,
      pinnedSectionRef,
    });
  }, [location, spirometryConfigs, measurementsConfigs, labResultsConfigs]);

  const handleOnClickToExpandLateralMenu = () => {
    setIsPatientLateralMenuOpen((prevState) => !prevState);
  };

  const handleOnEditingClick = (isEditing: boolean) => {
    setIsEditing(isEditing);
  };

  useEffect(() => {
    if (
      !isLabResultConfigFetching &&
      !isMeasurementConfigFetching &&
      !isSpirometryConfigFetching
    ) {
      const allConfigs = [
        ...spirometryConfigs,
        ...measurementsConfigs,
        ...labResultsConfigs,
      ];

      const isPinnedGraphValid =
        allConfigs.some(
          (config) => config.type === portalPreferences.pinnedMeasurementType
        ) &&
        portalPreferences.pinnedMeasurementType !== undefined &&
        portalPreferences.pinnedMeasurementType !== "";

      if (!isPinnedGraphValid) {
        hideChildRoute("Pinned");
      } else {
        showChildRoute("Pinned");
      }

      setHasPinnedGraph(isPinnedGraphValid);
    }
  }, [
    isLabResultConfigFetching,
    isMeasurementConfigFetching,
    isSpirometryConfigFetching,
    pinnedSectionRef,
    portalPreferences.pinnedMeasurementType,
  ]);

  useEffect(() => {
    const randomKey = randomIntFromInterval(1, 1000000).toString();

    setPinnedGraphKey(randomKey);
  }, [portalPreferences.pinnedMeasurementType]);

  if (isFetching && !isRefetching) return <LoadingOverlay />;

  return (
    collapsedSections && (
      <PatientPageContainer>
        <PatientPageContent
          isLateralPatientMenuOpenOnDesktop={isPatientLateralMenuOpen}
        >
          <PageLayout extraPadding>
            {hasPinnedGraph &&
            portalPreferences.pinnedMeasurementType !== "" &&
            portalPreferences.pinnedMeasurementType !== undefined ? (
              <StyledSection
                key={pinnedGraphKey}
                ref={pinnedSectionRef}
                positionRelative
              >
                <PinnedSection
                  collapsedSection={
                    collapsedSections.pinnedSection ?? defaultCollapsedSection
                  }
                  updateColapsedSections={updateColapsedSections}
                  patient={patient}
                  measurementType={
                    portalPreferences.pinnedMeasurementType as MeasurementTypes
                  }
                />
              </StyledSection>
            ) : null}

            {spirometryConfigs === null ||
            spirometryConfigs.length === 0 ||
            (spirometryConfigs as any).errors ? null : (
              <StyledSection
                ref={spirometrySectionRef}
                css={{ "@sm": { paddingTop: 0 } }}
              >
                <SpirometrySection
                  patient={patient}
                  collapsedSection={collapsedSections.spirometrySection}
                  updateColapsedSections={updateColapsedSections}
                />
              </StyledSection>
            )}

            {measurementsConfigs === null ||
            measurementsConfigs.length === 0 ||
            (measurementsConfigs as any).errors ? null : (
              <StyledSection ref={measurementsSectionRef}>
                <MeasurementSection
                  collapsedSection={collapsedSections.measurementSection}
                  updateColapsedSections={updateColapsedSections}
                  patient={patient}
                />
              </StyledSection>
            )}

            {labResultsConfigs === null ||
            labResultsConfigs.length === 0 ||
            (labResultsConfigs as any).errors ? null : (
              <StyledSection
                css={{ "@sm": { marginBottom: 90 } }}
                ref={labsSectionRef}
              >
                <LabsSection
                  collapsedSection={collapsedSections.labsSection}
                  updateColapsedSections={updateColapsedSections}
                  patient={patient}
                />
              </StyledSection>
            )}

            <StyledSection ref={alertsSectionRef} positionRelative>
              <AlertsSection
                collapsedSection={collapsedSections.alertsSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={promsSectionRef} positionRelative>
              <PromsSection
                collapsedSection={collapsedSections.promsSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={medicationSectionRef} positionRelative>
              <MedicationsSection
                collapsedSection={collapsedSections.medicationsSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={journalSectionRef} positionRelative>
              <JournalsSection
                collapsedSection={collapsedSections.journalsSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={notesSectionRef} positionRelative>
              <NotesSection
                collapsedSection={collapsedSections.notesSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={messagesSectionRef} positionRelative>
              <MessagesSection
                collapsedSection={collapsedSections.messagesSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>

            <StyledSection ref={devicesSectionRef} positionRelative>
              <DevicesSection
                collapsedSection={collapsedSections.devicesSection}
                updateColapsedSections={updateColapsedSections}
                patient={patient}
              />
            </StyledSection>
          </PageLayout>
        </PatientPageContent>

        <LateralMenuContainer isEditing={isEditing}>
          <PatientLateralMenuDesktop
            patientId={patientId}
            open={isPatientLateralMenuOpen}
            onExpandClick={handleOnClickToExpandLateralMenu}
            onEditingClick={handleOnEditingClick}
          />
        </LateralMenuContainer>

        <PatientSubNav
          isLateralPatientMenuOpenOnDesktop={isPatientLateralMenuOpen}
        />
      </PatientPageContainer>
    )
  );
}
