import { createContext, ReactNode, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  IHospitalConfiguration,
  ILogin,
  ILoginResponse,
  IMfaDetails,
  IWardConfiguration,
} from "../@types/Login";
import { IPortalPreferences, IStaffPreferences } from "../@types/Preferences";
import {
  AUTH_GRAPH_PREFERENCES,
  AUTH_HOSPITAL_CONFIGURATION,
  AUTH_INTERCOM_DETAILS,
  AUTH_PORTAL_PREFERENCES,
  AUTH_STAFF_PREFERENCES,
  AUTH_STORED_ROUTE,
  AUTH_UNIT_PREFERENCES,
  AUTH_USER_DETAILS,
  IS_MOBILE_APP,
  AUTH_WARD_CONFIGURATION,
} from "../constants/localStorageKeys";
import { useToken } from "../hooks/useToken";
import { authService } from "../services/authService";
import { mixpanelActions } from "../utils/mixpanel";

type IAuthContextData = {
  authenticate: (credentials: ILogin) => Promise<ILoginResponse>;
  isAuthenticated: boolean;
  signOut: () => void;
  token: string | null;
  saveDataInLocalStorage: ({ token, preferences }: ILocalStorageData) => void;
  loginDetails: ILogin | undefined;
  setLoginDetails: React.Dispatch<React.SetStateAction<ILogin | undefined>>;
  mfaDetails: IMfaDetails | undefined;
  setMfaDetails: React.Dispatch<React.SetStateAction<IMfaDetails | undefined>>;
};

type IAuthProviderProps = {
  children: ReactNode;
};

type ILocalStorageData = {
  token: any;
  preferences: any;
  portalPreferences: IPortalPreferences;
  intercomDetails: any;
  userDetails: any;
  hospitalConfiguration: IHospitalConfiguration;
  staffPreferences: IStaffPreferences;
  wardConfiguration: IWardConfiguration;
};

export const AuthContext = createContext({} as IAuthContextData);

export function AuthProvider({ children }: IAuthProviderProps) {
  const { token, setToken } = useToken();
  const [loginDetails, setLoginDetails] = useState<ILogin>();
  const [mfaDetails, setMfaDetails] = useState<IMfaDetails>();

  const navigate = useNavigate();

  function saveDataInLocalStorage({
    token,
    preferences,
    portalPreferences,
    intercomDetails,
    userDetails,
    hospitalConfiguration,
    staffPreferences,
    wardConfiguration,
  }: ILocalStorageData) {
    if (preferences) {
      localStorage.setItem(AUTH_UNIT_PREFERENCES, JSON.stringify(preferences));
    }

    if (portalPreferences) {
      localStorage.setItem(
        AUTH_PORTAL_PREFERENCES,
        JSON.stringify(portalPreferences)
      );
    }

    if (staffPreferences) {
      localStorage.setItem(
        AUTH_STAFF_PREFERENCES,
        JSON.stringify(staffPreferences)
      );
    }

    if (hospitalConfiguration) {
      localStorage.setItem(
        AUTH_HOSPITAL_CONFIGURATION,
        JSON.stringify(hospitalConfiguration)
      );
    }

    if (intercomDetails) {
      localStorage.setItem(
        AUTH_INTERCOM_DETAILS,
        JSON.stringify(intercomDetails)
      );
    }

    if (userDetails) {
      mixpanelActions.identify(userDetails.userId);
      localStorage.setItem(AUTH_USER_DETAILS, JSON.stringify(userDetails));
    }
    if (wardConfiguration) {
      localStorage.setItem(
        AUTH_WARD_CONFIGURATION,
        JSON.stringify(wardConfiguration)
      );
    }
    if (token) {
      setToken(token);
      const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
      if (!isMobileApp) {
        navigate("/");
      }
    }
  }

  async function authenticate({ email, password }: ILogin) {
    const responseFromAPI = await authService.authenticate({ email, password });

    const {
      token,
      preferences,
      portalPreferences,
      mfaDetails,
      intercomDetails,
      userDetails,
      hospitalConfiguration,
      staffPreferences,
      wardConfiguration,
    } = responseFromAPI;

    if (mfaDetails === null || mfaDetails === undefined)
      saveDataInLocalStorage({
        token,
        preferences,
        portalPreferences,
        intercomDetails,
        userDetails,
        hospitalConfiguration,
        staffPreferences,
        wardConfiguration,
      });

    const storedRoute = localStorage.getItem(AUTH_STORED_ROUTE);
    const isMobileApp = localStorage.getItem(IS_MOBILE_APP);
    if (storedRoute && !isMobileApp) {
      localStorage.removeItem(AUTH_STORED_ROUTE);
      navigate(storedRoute);
    }

    return responseFromAPI;
  }

  function signOut() {
    const graphPreferences = localStorage.getItem(AUTH_GRAPH_PREFERENCES);

    // clear all local storage (token, etc) but keeps graph preferences like scale and hide acceptability filters.
    localStorage.clear();
    mixpanelActions.reset();

    if (graphPreferences) {
      localStorage.setItem(AUTH_GRAPH_PREFERENCES, graphPreferences);
    }

    window.location.assign("/login");
  }

  const valuesToShare = useMemo(
    () => ({
      token,
      authenticate,
      signOut,
      isAuthenticated: Boolean(token),
      saveDataInLocalStorage,
      loginDetails,
      setLoginDetails,
      mfaDetails,
      setMfaDetails,
    }),
    [token, loginDetails, mfaDetails]
  );

  return (
    <AuthContext.Provider value={valuesToShare}>
      {children}
    </AuthContext.Provider>
  );
}
