import { IRoutePattern } from "../@types/Route";
import { Alert } from "../assets/icons/Alert";
import { Book } from "../assets/icons/Book";
import { DoubleFlag } from "../assets/icons/DoubleFlag";
import { File } from "../assets/icons/File";
import { Lab } from "../assets/icons/Lab";
import { Message } from "../assets/icons/Message";
import { Note } from "../assets/icons/Note";
import { People } from "../assets/icons/People";
import { PhoneDevice } from "../assets/icons/PhoneDevice";
import { Pill } from "../assets/icons/Pill";
import { PinnedGraph } from "../assets/icons/PinnedGraph";
import { Prom } from "../assets/icons/Prom";
import { Spirometry } from "../assets/icons/Spirometry";
import { Vitals } from "../assets/icons/Vitals";

export const patientListChildRoutes: IRoutePattern[] = [
  {
    name: "Active",
    path: "/patients/active",
    disabled: false,
    Icon: People,
    context: "Patient List",
  },
  {
    name: "Alerts",
    path: "/patients/alerts",
    disabled: false,
    Icon: Alert,
    context: "Patient List",
  },
  {
    name: "Flagged",
    path: "/patients/flagged",
    disabled: false,
    Icon: DoubleFlag,
    context: "Patient List",
  },
  {
    name: "Archived",
    path: "/patients/archived",
    disabled: false,
    Icon: File,
    context: "Patient List",
  },
];

export const patientPageChildRoutes: IRoutePattern[] = [
  {
    name: "Pinned",
    path: "/patients/:patientId/pinned",
    disabled: false,
    Icon: PinnedGraph,
    context: "Patient Page",
  },
  {
    name: "Spirometry",
    path: "/patients/:patientId/spirometry",
    disabled: false,
    Icon: Spirometry,
    context: "Patient Page",
  },
  {
    name: "Measurements",
    path: "/patients/:patientId/measurements",
    disabled: false,
    Icon: Vitals,
    context: "Patient Page",
  },
  {
    name: "Labs",
    path: "/patients/:patientId/labs",
    disabled: false,
    Icon: Lab,
    context: "Patient Page",
  },
  {
    name: "Alerts",
    path: "/patients/:patientId/alerts",
    disabled: false,
    Icon: Alert,
    context: "Patient Page",
  },
  {
    name: "PROM",
    path: "/patients/:patientId/proms",
    disabled: false,
    Icon: Prom,
    context: "Patient Page",
  },
  {
    name: "Medication",
    path: "/patients/:patientId/medication",
    disabled: false,
    Icon: Pill,
    context: "Patient Page",
  },
  {
    name: "Journal",
    path: "/patients/:patientId/journal",
    disabled: false,
    Icon: Book,
    context: "Patient Page",
  },
  {
    name: "Notes",
    path: "/patients/:patientId/notes",
    disabled: false,
    Icon: Note,
    context: "Patient Page",
  },
  {
    name: "Messages",
    path: "/patients/:patientId/messages",
    disabled: false,
    Icon: Message,
    context: "Patient Page",
  },
  {
    name: "Devices",
    path: "/patients/:patientId/devices",
    disabled: false,
    Icon: PhoneDevice,
    context: "Patient Page",
  },
];
