export function HorizontalDots({ ...rest }) {
  return (
    <svg
      {...rest}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.6667 7.99999C10.6667 8.73332 11.2667 9.33332 12 9.33332C12.7333 9.33332 13.3333 8.73332 13.3333 7.99999C13.3333 7.26666 12.7333 6.66666 12 6.66666C11.2667 6.66666 10.6667 7.26666 10.6667 7.99999ZM9.33334 7.99999C9.33334 7.26666 8.73334 6.66666 8.00001 6.66666C7.26668 6.66666 6.66668 7.26666 6.66668 7.99999C6.66668 8.73332 7.26668 9.33332 8.00001 9.33332C8.73334 9.33332 9.33334 8.73332 9.33334 7.99999ZM4.00001 6.66666C4.73334 6.66666 5.33334 7.26666 5.33334 7.99999C5.33334 8.73332 4.73334 9.33332 4.00001 9.33332C3.26668 9.33332 2.66668 8.73332 2.66668 7.99999C2.66668 7.26666 3.26668 6.66666 4.00001 6.66666Z"
        fill="#727272"
      />
    </svg>
  );
}
