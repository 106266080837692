import { useQuery } from "@tanstack/react-query";

import { MeasurementsConfig } from "../../@types/Measurements";
import { measurementsService } from "../../services/measurementsService";

export function useMeasurementConfigs({
  patientId,
  section,
}: {
  patientId: number | undefined;
  section: string | undefined;
}) {
  const { data, isError, isFetching } = useQuery(
    [`${section}-measurement-configs-${patientId}`, patientId],
    async () => {
      if (!patientId || !section) return [] as MeasurementsConfig[];

      const { data } = await measurementsService.getPatientMeasurementConfigs(
        patientId,
        section
      );

      return data;
    }
  );

  return {
    configs: data ?? [],
    isFetching,
    isError,
  };
}

export function useMeasurementTypeConfig({
  measurementType,
}: {
  measurementType: string;
}) {
  const { data, isError, isFetching } = useQuery(
    [`measurement-type-config-${measurementType}`],
    async () => {
      const { data } = await measurementsService.getMeasurementTypeConfig(
        measurementType
      );

      return data;
    }
  );

  return {
    config: data,
    isFetching,
    isError,
  };
}
