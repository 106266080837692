// scoring

export const KbildDomains = [
  "Breathlessness and activities",
  "Chest symptoms",
  "Psychological",
];

export const LpfSymptomsDomains = ["Dyspnea", "Cough", "Fatigue", "Oxygen"];

export const ScoreStrategiesWithFixedDomains = [
  "KBILD",
  "LpfSymptomsScore",
  "LpfImpactScore",
];

export const ScoreStrategiesWithDomains = [
  "KBILD",
  "LpfImpactScore",
  "TotalSum",
  "AverageDomainTotalSum",
  "LpfSymptomsScore",
];
