export function PinnedGraph({ ...rest }) {
  return (
    <svg
      {...rest}
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.64987 19.4361C4.07268 19.4361 3.58081 19.2328 3.17427 18.8263C2.76772 18.4197 2.56445 17.9279 2.56445 17.3507V4.64889C2.56445 4.0717 2.76772 3.57983 3.17427 3.17329C3.58081 2.76675 4.07268 2.56348 4.64987 2.56348H12.8176V4.64889H4.64987V17.3507H17.3517V9.18296H19.4371V17.3507C19.4371 17.9279 19.2338 18.4197 18.8273 18.8263C18.4207 19.2328 17.9289 19.4361 17.3517 19.4361H4.64987ZM6.4502 15.5831H8.28354V9.16646H6.4502V15.5831ZM10.0841 15.5831H11.9174V6.41646H10.0841V15.5831ZM13.718 15.5831H15.5513V11.9165H13.718V15.5831ZM15.6279 8.26629V6.37269H13.7343V4.45708H15.6279V2.56348H17.5435V4.45708H19.4371V6.37269H17.5435V8.26629H15.6279Z"
        fill="#666666"
      />
    </svg>
  );
}
