import {
  Button,
  css,
  IOption,
  LinkButton,
  SelectDropdown,
  SkeletonLoading,
  TextField,
  TimePickerInput,
} from "@patientmpower/spiro";
import { AxiosResponse } from "axios";
import dayjs, { Dayjs } from "dayjs";
import { ReactElement, useEffect, useState } from "react";

import {
  AddMeasurementType,
  MeasurementType,
} from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import { IUserPreferences } from "../../../../@types/Preferences";
import {
  AUTH_UNIT_PREFERENCES,
  IS_MOBILE_APP,
} from "../../../../constants/localStorageKeys";
import { measurementsService } from "../../../../services/measurementsService";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { ErrorMessage } from "../../../PatientList/components/AddPatientModal/AddPatientModal.styles";
import { SubmitContainer } from "../ResolveTriggerModal/ResolveTriggerModal.styles";
import {
  ContainerRow,
  ModalContainer,
  NoteSection,
  SectionContainer,
  Title,
  UnitParagraph,
} from "./AddMeasurementModal.styles";

type AddMeasurementModalProps = {
  section: string;
  patientId: string;
  onClose(notification?: Notification): void;
  measurementType?: string;
};

export function AddMeasurementModal({
  section,
  patientId,
  onClose,
  measurementType,
}: AddMeasurementModalProps) {
  const [measurementTypes, setMeasurementTypes] = useState<MeasurementType[]>(
    []
  );
  const [updateTypesDropdown, setUpdateTypesDropdown] = useState(false);
  const [responseError, setResponseError] = useState<string>("");
  const [isMobileApp, setIsMobileApp] = useState(false);

  const [category, setCategory] = useState<string | undefined>();
  const [usingDevice, setUsingDevice] = useState("true");
  const [type, setType] = useState(measurementType ?? "");
  const [unit, setUnit] = useState("--");

  const [inputValue, setInputValue] = useState("");
  const [limits, setLimits] = useState<number[]>([]); // [min, max]
  const [bpSystolicValue, setBpSystolicValue] = useState("");
  const [bpDiastolicValue, setBpDiastolicValue] = useState("");

  const [dateValue, setDateValue] = useState(dayjs().format("DD/MM/YY"));
  const [timeValue, setTimeValue] = useState(dayjs());

  const [categoryOptions, setCategoryOptions] = useState<IOption[]>([]);
  const [typeOptions, setTypeOptions] = useState<IOption[]>([]);

  const usingDeviceOptions: IOption[] = [
    {
      label: "Yes",
      value: "true",
      key: "using-device-yes-key",
    },
    {
      label: "No",
      value: "false",
      key: "using-device-no-key",
    },
  ];

  const inputCss = css({
    span: {
      margin: 0,
    },
  });

  const handleTypeDropdownOptions = (types?: MeasurementType[]) => {
    const typeOptions: IOption[] = [];

    if (types) {
      if (types) {
        types.forEach((type, index) => {
          if (type.category === category) {
            typeOptions.push({
              label: type.measurementTypeDisplayName,
              value: type.measurementType,
              key: `${type.measurementType}-${index}-key`,
            });
          }
        });
      }

      setTypeOptions(typeOptions);
      return;
    }

    if (measurementTypes) {
      measurementTypes.forEach((type, index) => {
        if (type.category === category) {
          typeOptions.push({
            label: type.measurementTypeDisplayName,
            value: type.measurementType,
            key: `${type.measurementType}-${index}-key`,
          });
        }
      });
    }

    setTypeOptions(typeOptions);
  };

  useEffect(() => {
    setType("");
    setUnit("--");
    setInputValue("");
    setBpSystolicValue("");
    setBpDiastolicValue("");
    setUpdateTypesDropdown(false);
    setResponseError("");
  }, [updateTypesDropdown]);

  useEffect(() => {
    if (type !== "") {
      const measurement = measurementTypes.find(
        (x) => x.measurementType === type
      );

      if (!measurement) return;

      setLimits([measurement.min, measurement.max]);

      if (measurement.units.length === 1) {
        if (measurement.units[0]) {
          setUnit(measurement.units[0]);
          return;
        }
      }

      const preferences = localStorage.getItem(AUTH_UNIT_PREFERENCES);

      const parsedPreferences = preferences
        ? (JSON.parse(preferences) as IUserPreferences)
        : undefined;

      if (parsedPreferences === undefined) {
        if (measurement.units[0]) {
          setUnit(measurement.units[0]);
        }
      } else {
        let unitToUse = "--";

        switch (measurement.measurementType) {
          case "temperature":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.temperatureUnit
              ) ?? "--";
            break;
          case "height":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.heightUnit
              ) ?? "--";
            break;
          case "weight":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.weightUnit
              ) ?? "--";
            break;
          case "pef":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.flowRateUnit
              ) ?? "--";
            break;
          case "blood_glucose":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.bloodGlucoseUnit
              ) ?? "--";
            break;
          case "phosphate":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.phosphateUnit
              ) ?? "--";
            break;
          case "creatinine":
            unitToUse =
              measurement.units.find(
                (x) => x === parsedPreferences.creatinineUnit
              ) ?? "--";
            break;
          case "dyspnea":
            unitToUse = "--";
            break;
          default:
            unitToUse = measurement.units[0] ?? "--";
            break;
        }

        setUnit(unitToUse);
      }
    }
    setResponseError("");
  }, [type]);

  useEffect(() => {
    measurementsService
      .getPatientMeasurementTypes(patientId?.toString())
      .then((result) => {
        const values = result.data.values as unknown;
        const measurementTypes = values as MeasurementType[];

        const newMeasurementTypes = measurementTypes.filter(
          (x) => x.measurementType !== "bp_diastolic"
        );

        const categoryOpts = newMeasurementTypes.reduce(
          (categoryOpts: IOption[], measurement) => {
            const { category, categoryDisplayName } = measurement;
            if (!categoryOpts.find((co) => co.value === category)) {
              categoryOpts.push({
                label: categoryDisplayName,
                value: category,
                key: category,
              });
            }

            return categoryOpts;
          },
          []
        );

        setCategoryOptions(categoryOpts);
        setMeasurementTypes(newMeasurementTypes);
        handleTypeDropdownOptions(newMeasurementTypes);

        setCategory(section);
        setType(measurementType ?? "");
      });

    const storedIsMobileApp = localStorage.getItem(IS_MOBILE_APP);

    if (storedIsMobileApp === "true") {
      setIsMobileApp(true);
    }
  }, [section]);

  useEffect(() => {
    handleTypeDropdownOptions();
    setResponseError("");
  }, [category]);

  const handleConfirmAddValue = async () => {
    const parsedValue = parseFloat(inputValue);
    const parsedSytolicBp = parseFloat(bpSystolicValue);
    const parsedDiastolicBp = parseFloat(bpDiastolicValue);

    if (type === "bp_systolic") {
      if (
        (parsedSytolicBp < limits[0] || parsedSytolicBp > limits[1]) &&
        (parsedDiastolicBp < limits[0] || parsedDiastolicBp > limits[1])
      ) {
        setResponseError(
          `Please insert values between ${limits[0]} and ${limits[1]}`
        );
        return;
      }
      if (parsedSytolicBp < limits[0] || parsedSytolicBp > limits[1]) {
        setResponseError(
          `Please insert a value between ${limits[0]} and ${limits[1]} for sytolic`
        );
        return;
      }

      if (parsedDiastolicBp < limits[0] || parsedDiastolicBp > limits[1]) {
        setResponseError(
          `Please insert a value between ${limits[0]} and ${limits[1]} for diastolic`
        );
        return;
      }
    }

    if (parsedValue < limits[0] || parsedValue > limits[1]) {
      setResponseError(
        `Please insert a value between ${limits[0]} and ${limits[1]}`
      );
      return;
    }

    const splitedDate = dateValue.split("/");

    const day = splitedDate[0];
    const month = splitedDate[1];
    const year = splitedDate[2];

    const builtDate = `${month}/${day}/${year}`;
    const dateToSend = dayjs(
      `${dayjs(builtDate).format("MM/DD/YYYY")} ${timeValue.format("HH:mm")}`
    ).toISOString();

    if (!dayjs(dateToSend).isBefore(dayjs())) {
      setResponseError("Please select a time that is not in the future.");
      return;
    }

    mixpanelActions.track(`User action: Confirm`);

    let result: AxiosResponse;

    if (type === "bp_systolic") {
      const addMeasurementObj: AddMeasurementType = {
        type: "bp_systolic, bp_diastolic",
        unit: unit === "--" ? "" : unit,
        patientId: parseInt(patientId, 10),
        time: dateToSend,
        locationType: "Clinic",
        bp_systolic: parseFloat(bpSystolicValue),
        bp_diastolic: parseFloat(bpDiastolicValue),
      };

      result = await measurementsService.addPatientMeasurement(
        addMeasurementObj
      );
    } else {
      const addMeasurementObj: AddMeasurementType = {
        type,
        unit: unit === "--" ? "" : unit,
        patientId: parseInt(patientId, 10),
        time: dayjs(
          `${dayjs(dateToSend).format("MM/DD/YYYY")} ${timeValue.format(
            "HH:mm"
          )}`
        ).toISOString(),
        locationType: "Clinic",
        [type]: parseFloat(inputValue),
      };

      result = await measurementsService.addPatientMeasurement(
        addMeasurementObj
      );
    }

    if (
      result.status !== undefined &&
      result.status > 200 &&
      result.status < 300
    ) {
      onClose({
        show: true,
        message: "Value successfully added",
        type: "success",
        width: "max-content",
        rightMargin: "10%",
      });
    } else {
      onClose({
        show: true,
        message: "Sorry, something went wrong. Please try again",
        type: "error",
        width: "max-content",
        rightMargin: "45%",
      });
    }
  };

  const handleCancel = () => {
    mixpanelActions.track(`User action: Cancel`);
    onClose();
  };

  const handleOnTimeChange = (time: Dayjs | null) => {
    if (time != null) {
      setTimeValue(time);
    } else {
      setTimeValue(dayjs());
    }
  };

  const handleButtonDisabled = () => {
    if (type === "bp_systolic") {
      if (bpSystolicValue === "" || bpDiastolicValue === "") {
        return true;
      }

      return false;
    }

    if (inputValue === "" || dateValue.length !== 8) {
      return true;
    }

    return false;
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;

    setInputValue(value);
  };

  const handleBpSystolicChange = (event: any) => {
    const { value } = event.target;

    setBpSystolicValue(value);
  };

  const handleBpDiastolicChange = (event: any) => {
    const { value } = event.target;

    setBpDiastolicValue(value);
  };

  const handleModalRows = () => {
    const rowsToDisplay: ReactElement[] = [];

    if (isMobileApp) {
      rowsToDisplay.push(
        <ContainerRow>
          <p>Using Device</p>
          <SelectDropdown
            value={usingDevice}
            options={usingDeviceOptions}
            width={191.5}
            onValueChange={(usingDevice) => {
              setUsingDevice(usingDevice.toString());
            }}
          />
        </ContainerRow>
      );
    }

    if (usingDevice === "false" || !isMobileApp) {
      rowsToDisplay.push(
        <>
          <ContainerRow>
            <p>Type</p>
            {!updateTypesDropdown ? (
              <SelectDropdown
                value={type}
                options={typeOptions}
                width={191.5}
                onValueChange={(type) => {
                  mixpanelActions.track(`User action: Select ${type}`);
                  setType(type.toString());
                  setInputValue("");
                  setBpSystolicValue("");
                  setBpDiastolicValue("");
                  setResponseError("");
                }}
              />
            ) : null}
          </ContainerRow>
          <ContainerRow disabled={type === ""}>
            <p>Time</p>
            <TimePickerInput value={timeValue} onChange={handleOnTimeChange} />
          </ContainerRow>
          <ContainerRow disabled={type === ""}>
            <p>Start date</p>
            <TextField
              label=""
              placeholder="dd/mm/yy"
              type="date"
              backgroudColor="white"
              maxLength={8}
              value={dateValue}
              className={inputCss()}
              onChange={(event) => setDateValue(event.target.value)}
            />
          </ContainerRow>
          <ContainerRow disabled={type === ""}>
            <p>Unit</p>
            <UnitParagraph>{unit}</UnitParagraph>
          </ContainerRow>
          {type !== "bp_systolic" ? (
            <ContainerRow disabled={type === ""}>
              <p>Value</p>
              <TextField
                label=""
                placeholder="0"
                type="number"
                backgroudColor="white"
                value={inputValue}
                className={inputCss()}
                onChange={handleInputChange}
              />
            </ContainerRow>
          ) : (
            <>
              <ContainerRow>
                <p>Systolic</p>
                <TextField
                  label=""
                  placeholder="0"
                  type="number"
                  backgroudColor="white"
                  value={bpSystolicValue}
                  className={inputCss()}
                  onChange={handleBpSystolicChange}
                />
              </ContainerRow>
              <ContainerRow>
                <p>Diastolic</p>
                <TextField
                  label=""
                  placeholder="0"
                  type="number"
                  backgroudColor="white"
                  value={bpDiastolicValue}
                  className={inputCss()}
                  onChange={handleBpDiastolicChange}
                />
              </ContainerRow>
            </>
          )}
        </>
      );
    }

    return rowsToDisplay;
  };

  const handleOpenApp = () => {
    (window as { [key: string]: any }).sendMessageToFlutter(String(patientId));
  };

  return (
    <ModalContainer>
      <Title>
        Add {category !== "lab_result" ? category : "lab"}
        {!category && <SkeletonLoading width="100px" />}
      </Title>

      <SectionContainer>
        <ContainerRow>
          <p>Category</p>
          <SelectDropdown
            value={category}
            options={categoryOptions}
            width={191.5}
            onValueChange={(category) => {
              setCategory(category.toString());
              setUpdateTypesDropdown(true);
            }}
          />
        </ContainerRow>

        {handleModalRows()}
      </SectionContainer>

      <ErrorMessage
        style={{ position: "relative", left: "0px", marginBottom: "16px" }}
      >
        {responseError}
      </ErrorMessage>

      {usingDevice === "true" && isMobileApp ? (
        <NoteSection>
          <p style={{ fontWeight: "600" }}>Please note</p>
          <p>
            The button below to begin a spirometry blow in app. The
            patientMpower app must be installed and you must be logged in on
            this device.
          </p>
        </NoteSection>
      ) : null}

      <SubmitContainer noPadding>
        {usingDevice === "true" && isMobileApp ? (
          <Button label="Open app" type="button" onClick={handleOpenApp} />
        ) : (
          <Button
            label="Confirm"
            type="button"
            onClick={handleConfirmAddValue}
            disabled={handleButtonDisabled()}
          />
        )}
        <LinkButton
          label="Cancel"
          size="lg"
          color="black"
          fontWeight="semiBold"
          onClick={handleCancel}
        />
      </SubmitContainer>
    </ModalContainer>
  );
}
