import { createContext, ReactNode, useMemo, useState } from "react";
import { matchPath, useLocation } from "react-router-dom";

import { IRoutePattern } from "../@types/Route";
import { routesWithChildRoutes } from "../routes/routes";

type IChildRoutesContextData = {
  childRoutes: Array<IRoutePattern>;
  hideChildRoute: (name: string) => void;
  showChildRoute: (name: string) => void;
};

type IChildRoutesProvider = {
  children: ReactNode;
};

export const ChildRoutesContext = createContext({} as IChildRoutesContextData);

export function ChildRoutesProvider({ children }: IChildRoutesProvider) {
  const [routes, setRoutes] = useState(routesWithChildRoutes);
  const [hiddenRoutes, setHiddenRoutes] = useState<IRoutePattern[]>([]);
  const location = useLocation();

  async function hideChildRoute(name: string) {
    const newHiddenRoutes: IRoutePattern[] = [];

    const updatedRoutes = routesWithChildRoutes.map((route) => {
      const hiddenChildren = route.childRoutes.filter(
        (childRoute) => childRoute.name === name
      );
      if (hiddenChildren.length > 0) {
        newHiddenRoutes.push(...hiddenChildren);
      }

      return {
        paths: route.paths,
        childRoutes: route.childRoutes.filter(
          (childRoute) => childRoute.name !== name
        ),
      };
    });

    setHiddenRoutes((prevHidden) => [...prevHidden, ...newHiddenRoutes]);
    setRoutes(updatedRoutes);
  }

  async function showChildRoute(name: string) {
    const routesToRestore = hiddenRoutes.filter(
      (hiddenRoute) => hiddenRoute.name === name
    );

    const updatedRoutes = routesWithChildRoutes.map((route) => {
      const restoredChildren = routesToRestore.filter(
        (childRoute) =>
          !route.childRoutes.some((cr) => cr.name === childRoute.name)
      );

      return {
        paths: route.paths,
        childRoutes: [...route.childRoutes, ...restoredChildren],
      };
    });

    const remainingHiddenRoutes = hiddenRoutes.filter(
      (hiddenRoute) => hiddenRoute.name !== name
    );
    setHiddenRoutes(remainingHiddenRoutes);

    setRoutes(updatedRoutes);
  }

  const valuesToShare = useMemo(() => {
    /**
     * This piece of code will match the routes that have the same
     * child routes.
     */
    const activeChildRoute =
      routes.find(({ paths }) => {
        return paths.find((path) =>
          matchPath(
            {
              path,
            },
            location.pathname
          )
        );
      })?.childRoutes || [];

    return {
      childRoutes: activeChildRoute,
      hideChildRoute,
      showChildRoute,
    };
  }, [location, routes]);

  return (
    <ChildRoutesContext.Provider value={valuesToShare}>
      {children}
    </ChildRoutesContext.Provider>
  );
}
