import dayjs from "dayjs";
import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";

import { MeasurementTypes } from "../../../../@types/Measurements";
import { Notification } from "../../../../@types/Notification";
import { ISectionProps } from "../../../../@types/Patient";
import { ChevronDown } from "../../../../assets/icons/ChevronDown";
import { ChevronUp } from "../../../../assets/icons/ChevronUp";
import { CustomIconButton } from "../../../../components/CustomIconButton";
import { ToastNotification } from "../../../../components/ToastNotification";
import { useMeasurementConfigs } from "../../../../hooks/queries/measurement-configs";
import useIsMobile from "../../../../hooks/useIsMobile";
import { useModal } from "../../../../hooks/useModal";
import { useUserPreferences } from "../../../../hooks/useUserPreferences";
import { PageTitle } from "../../../../layout/components/PageTitle";
import { mixpanelActions } from "../../../../utils/mixpanel";
import { randomIntFromInterval } from "../../../../utils/random";
import {
  ChartsContainer,
  MobilePatientPageTitleContainer,
} from "../../PatientPage.styles";
import { AddMeasurementModal } from "../AddMeasurementModal";
import {
  LocationMenuOption,
  LocationMenuOptionProps,
} from "../LocationMenuOption";
import { MeasurementCard } from "../MeasurementCard";
import {
  AddMeasurementButton,
  CardsContainer,
  NewDataCOntainer,
  RoutesPatientPageMobile,
  SpirometrySectionTitle,
  TitleContainer,
} from "./SpirometrySection.styles";

export function SpirometrySection({
  patient,
  updateColapsedSections,
  collapsedSection,
}: ISectionProps) {
  const { portalPreferences } = useUserPreferences();
  const { isMobile } = useIsMobile();
  const { openModal, closeModal } = useModal();
  const navigate = useNavigate();

  const { configs } = useMeasurementConfigs({
    patientId: patient?.id,
    section: "spirometry",
  });

  const [notification, setNotification] = useState<Notification>({
    show: false,
    message: "",
    type: "error",
    width: undefined,
    rightMargin: "25%",
  });

  const [showNewData, setShowNewData] = useState<boolean>(false);

  const locationConfigs: LocationMenuOptionProps[] = [
    {
      keyLabel: `patient-list-${randomIntFromInterval(1, 10000)}`,
      label: "Patient List",
      onClick: () => {
        navigate("/");
      },
      active: true,
    },
    {
      label: "Patient Page",
      active: false,
      keyLabel: `patient-page-${randomIntFromInterval(1, 10000)}`,
      onClick: () => {},
    },
  ];

  const handleOnModalClose = (notification?: Notification) => {
    if (notification !== undefined) {
      setNotification(notification);
      setTimeout(() => {
        window.location.reload();
      }, 500);
    }
    closeModal();
  };

  const checkNewData = (mostRecentDate: dayjs.Dayjs) => {
    if (showNewData === false) {
      const collapsedDate = dayjs(collapsedSection.collapsedDate);
      if (mostRecentDate > collapsedDate) {
        setShowNewData(true);
      }
    }
  };

  const handleConfigs = () => {
    if (configs === null || configs.length === 0 || (configs as any).errors)
      return null;
    const configToReturn: any[] = [];

    configs
      .filter(
        (measurement) =>
          measurement.type !== portalPreferences.pinnedMeasurementType
      )
      .forEach((measurement, index) =>
        configToReturn.push(
          <MeasurementCard
            key={`${measurement.title}_${measurement.type}_${index}`}
            cardTitle={`${measurement.title} ${
              measurement.unit ? `(${measurement.unit})` : ""
            }`}
            unit={measurement.unit}
            measurementType={measurement.type as MeasurementTypes}
            minValue={measurement.min}
            maxValue={measurement.max}
            isSpirometry
            checkNewData={checkNewData}
          />
        )
      );

    return configToReturn;
  };

  const handleAddSpirometry = () => {
    mixpanelActions.track(`User action: Add Spirometry Button`);
    openModal(
      <AddMeasurementModal
        section="spirometry"
        patientId={patient?.id.toString()}
        onClose={handleOnModalClose}
      />,
      {
        width: isMobile ? "98vw" : "620px",
        height: isMobile ? undefined : "auto",
        borderRadius: isMobile ? "8px" : undefined,
      }
    );
  };

  const handleCollapseSection = () => {
    updateColapsedSections("spirometrySection");
    if (collapsedSection.value === true) {
      setShowNewData(false);
    }
  };

  return (
    <>
      {!isMobile && (
        <TitleContainer>
          <SpirometrySectionTitle>Spirometry</SpirometrySectionTitle>
          {showNewData && <NewDataCOntainer>(new data)</NewDataCOntainer>}
          <CustomIconButton
            style={{ marginLeft: "2px" }}
            onClick={handleCollapseSection}
          >
            {collapsedSection.value ? <ChevronUp /> : <ChevronDown />}
          </CustomIconButton>
        </TitleContainer>
      )}

      <ChartsContainer
        style={{
          height: collapsedSection.value ? "0" : "",
          overflow: collapsedSection.value ? "hidden" : "",
        }}
      >
        <AddMeasurementButton onClick={handleAddSpirometry}>
          Add spirometry
        </AddMeasurementButton>
        {isMobile && (
          <>
            <RoutesPatientPageMobile>
              {locationConfigs.map((config: LocationMenuOptionProps, index) => {
                if (index !== 0) {
                  return (
                    <Fragment key={config.keyLabel}>
                      <p> - </p>
                      <LocationMenuOption
                        keyLabel={config.keyLabel}
                        label={config.label}
                        onClick={config.onClick}
                        active={config.active}
                      />
                    </Fragment>
                  );
                }
                return (
                  <LocationMenuOption
                    key={config.keyLabel}
                    keyLabel={config.keyLabel}
                    label={config.label}
                    onClick={config.onClick}
                    active={config.active}
                  />
                );
              })}
            </RoutesPatientPageMobile>
            <MobilePatientPageTitleContainer>
              <PageTitle>Spirometry</PageTitle>
            </MobilePatientPageTitleContainer>
          </>
        )}

        <CardsContainer>{handleConfigs()}</CardsContainer>
      </ChartsContainer>
      {notification.show ? (
        <ToastNotification
          message={notification.message}
          type={notification.type}
          width={notification.width}
          rightMargin="25%"
        />
      ) : null}
    </>
  );
}
